import React from "react"
import { StoryGroup } from "components"
import { flatStoryGroup } from "../utils/array-util"

const LinkMap = {}

const storiesFoundation = [
  {
    title: "Animations",
    postId: "e019a4811516",
  },
  {
    title: "Audio",
    postId: "c7b0595447fa",
  },
  {
    title: "Colors",
    postId: "06abc15c5b12",
  },
  {
    title: "Icons",
    postId: "536147e7b688",
  },
  {
    title: "Interactions",
    postId: "6309eba3b2bf",
  },
  {
    title: "Typography",
    postId: "59841b5dd3e5",
  },
  {
    title: "Video",
    postId: "2677ab5b76d4",
  },
  {
    title: "Localization",
    postId: "89fd03383f09",
  },
]

const storiesCore = [
  {
    title: "Authorization",
    postId: "8bbbfd4f1c12",
  },
  {
    title: "Client State",
    postId: "57a16b1d235a",
  },
  {
    title: "Crud Operations",
    postId: "4bb389d83680",
  },
  {
    title: "Custom Hooks",
    postId: "f3a3b6027f71",
  },
  {
    title: "Modules",
    postId: "40fec30f67de",
  },
  {
    title: "Network",
    postId: "35c9a1672aca",
  },
  {
    title: "Performance",
    postId: "9ed16a3b6e65",
  },
  {
    title: "React Query",
    postId: "c05e1410a59b",
  },
  {
    title: "Routing",
    postId: "5a49ff66b09f",
  },
  {
    title: "Zustand",
    postId: "fcbfeb71fc68",
  },
]

const storiesLayout = [
  {
    title: "Complex Layout",
    postId: "6c7a75948b98",
  },
  {
    title: "Dashboard Layout",
    postId: "11d530efb475",
  },
  {
    title: "Flex Layout",
    postId: "222ba378c7f2",
  },
  {
    title: "Grid Layout",
    postId: "d3d187bee0c3",
  },
  {
    title: "Menu Layout",
    postId: "e0141c49834e",
  },
  {
    title: "Pixel Layout",
    postId: "0eaee96b9cb5",
  },
  {
    title: "Sample Layout",
    postId: "3482e759c928",
  },
]

const storiesBlock = [
  {
    title: "Avatar",
    postId: "21718ba58aee",
  },
  {
    title: "Breadcrumbs",
    postId: "d4c70b6225ee",
  },
  {
    title: "Button",
    postId: "02eb70b7f67e",
  },
  {
    title: "Calculator",
    postId: "e2b53c1eb244",
  },
  {
    title: "Cards",
    postId: "9f5d8166e16f",
  },
  {
    title: "Drawer",
    postId: "21d7a9329231",
  },
  {
    title: "Loaders",
    postId: "a63f11a93e68",
  },
  {
    title: "Option Selector",
    postId: "da0e126a1792",
  },
  {
    title: "SingIn & SingUp",
    postId: "a376bd99febb",
  },
]

const storiesVisualization = [
  {
    title: "Charts",
    postId: "d57b178587dd",
  },
  {
    title: "Cytoscape",
    postId: "45dd84a1507d",
  },
  {
    title: "FlameGraph",
    postId: "e9d9e6ab0db5",
  },
  {
    title: "Heatmap",
    postId: "6a192db6624e",
  },
  {
    title: "Map",
    postId: "bef3f3849b32",
  },
  {
    title: "Periodic Table",
    postId: "e78acf188972",
  },
  {
    title: "Statistics",
    postId: "3afb497f12ed",
  },
  {
    title: "TraceChart",
    postId: "124e2c918e47",
  },
  {
    title: "ReactFlow",
    postId: "2cbb0bab4404",
  },
  {
    title: "React Visjs",
    postId: "e53f03ff4db5",
  },
]

const storiesDevelopment = [
  {
    title: "Code Editor",
    postId: "9d4cd2f3fcad",
  },
  {
    title: "GitHub Explorer",
    postId: "891c92a2a65c",
  },
  {
    title: "JSON Viewer",
    postId: "11a1ba4e0224",
  },
  {
    title: "Log Viewer",
    postId: "6963fdf0e066",
  },
  {
    title: "Markdown",
    postId: "32cb08ba8124",
  },
  {
    title: "PDF",
    postId: "74997e0c44c7",
  },
]

const storiesDesigner = [
  {
    title: "NoCode Counter",
    postId: "6662105dfffb",
  },
  {
    title: "NoCode Form",
    postId: "249efbb1482b",
  },
  {
    title: "SmartLayout",
    postId: "ebfb343e90d7",
  },
  {
    title: "Visual SQL Editor",
    postId: "f0f90fccaba2",
  },
]

const storiesPage = [
  {
    title: "AI Chat Page",
    postId: "7ed24a18dc88",
  },
  {
    title: "Dashboard Page",
    postId: "762c0b37b522",
  },
  {
    title: "DevTools Page",
    postId: "524dc76025c4",
  },
  {
    title: "Kanban Board Page",
    postId: "1f77c764c32c",
  },
]

const storiesGroup = [
  {
    title: "Foundations",
    storyPart: storiesFoundation,
  },
  {
    title: "Core",
    storyPart: storiesCore,
  },
  {
    title: "Layout",
    storyPart: storiesLayout,
  },
  {
    title: "Block",
    storyPart: storiesBlock,
  },
  {
    title: "Visualization",
    storyPart: storiesVisualization,
  },
  {
    title: "Development",
    storyPart: storiesDevelopment,
  },
  {
    title: "Designer",
    storyPart: storiesDesigner,
  },
  {
    title: "Page",
    storyPart: storiesPage,
  },
]

export const context = {
  stories: flatStoryGroup(storiesGroup),
  linkMap: LinkMap,
  title: "React Knowledge Maps",
  path: "react-knowledge-maps",
}

const ReactKnowledgeMapsPage = () => {
  return (
    <StoryGroup
      title={context.title}
      complex={true}
      stories={storiesGroup}
      linkMap={context.linkMap}
    />
  )
}

export default ReactKnowledgeMapsPage
